import React from "react";
import SpotlightContainer from "../spotlight";
import Java from "../../../images/java.png";
import JavaEE from "../../../images/javaee.png";
import JBoss from "../../../images/jboss.png";
import Image from "../../../images/sd3.png";

function Project() {
  const jobData = {
    jobName: "Send",
    button: "View",
    link: "https://send.flutterwave.com/",
    image: Image,
    stack: [
      {
        name: "Java",
        image: Java,
      },
      {
        name: "Java EE",
        image: JavaEE,
      },
      {
        name: "JBoss",
        image: JBoss,
      },
    ],
    description:
      "I contributed to enhancing the fraud and compliance monitoring system by optimizing the implementation of Forter, LexisNexis, and Unit 21 systems. This project involved refining the integration processes to improve detection accuracy, streamline workflows, and strengthen the overall security infrastructure. The enhancements resulted in more reliable fraud prevention and compliance adherence across the organization.",
    roles: [],
  };

  return <SpotlightContainer jobData={jobData} />;
}
export default Project;
